<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  methods: {
    isLogin() {
      return;
      // alert('!!!!')
    },
  },
  created() {
     this.$get('/chat/imUser/queryListPage',{userId:'liasd',message:'消息都发单号'})
    this.isLogin();
  },
};
</script>

<style lang="less">
.el-dialog {
  @media screen and(max-width: 768px) {
    width: 90% !important;
  }
}

.el-tabs {
  background-color: #ffffff;

  .el-tabs__item {
    font-size: 16px !important;

    &:hover {
      color: #ff7e00 !important;
    }
  }

  .is-active {
    color: #ff7e00 !important;
  }

  .el-tabs__active-bar {
    background-color: #ff7e00 !important;
    border: none;
  }

  .el-tabs__header {
    margin-bottom: 8px;
  }
}

// .el-button {
//   background-color: #ff7e00;
//   border:none;
// }
.bg-white{
  background-color:#fff;
}
.padd17{
  padding:17px 0 0 17px;
}
.bottom20{
  background-color: #eaeaea;
  height:20px;
}
.UserFacts {
  display: flex;
  background-color: #ffffff;
  padding: 10px 20px 20px 20px;
 flex-wrap:wrap ;
  .Facts {
    display: flex;
    width: 220px;
    height: 72px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 17px 0 14px 30px;
    margin-right: 50px;
    .img {
      width: 34px;
      height: 38px;
      margin-right: 20px;
    }
    .div-p {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      .p-1 {
        color: #434343;
      }
      .p-2 {
        color: #808080;
      }
    }
  }

}
</style>
