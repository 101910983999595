
const EVENT = {
	SDK_READY:'login',
	SDK_NOT_READY: 'disconnect',
	MESSAGE_RECEIVED: 'message_received',
	CONVERSATION_LIST_UPDATED:'conversation_list_update',
	MESSAGE_REVOKED: 'message_revoked',
    TOTAL_UNREAD_MESSAGE_COUNT_UPDATED: 'total_unread_update'
}


const TYPES = {
	CONV_C2C:'C2C',
}

export default {EVENT,TYPES};