import io from 'socket.io-client';

import api from '../../api/index.js'
import {
	guid
} from '../utils.js'

import {getPicKeyName} from "@/utils/utils.js"
import { Message } from 'element-ui';


let socketUrl = '' //socket地址
if (process.env.NODE_ENV === 'development') {
	//开发环境 运行时
	socketUrl = 'http://192.168.1.136:9988'
	// socketUrl = 'https://curiousmore.com'
} else {
	//生产环境  发布时
	socketUrl = 'https://curiousmore.com'
}

let socket = null
let imUser = {}
let loginStatus = 'no'

function createSocket() {
	if (!socket) {
		socket = io(socketUrl, {
			transports: ['websocket'], // 此项必须设置
			reconnectionAttempts: 3, // 失败后重新连接次数
			reconnectionDelay: 2000, // 重新连接间隔时间毫秒,
			autoConnect: false
		})
	}


	socket.on('connect', () => {
		console.log('连接成功');
	})
	socket.on('connect_timeout', d => {
		// console.log('SOCKET连接超时');
	})
	socket.on('error', err => {
		// console.log(error, '错误信息');
		// console.log('SOCKET连接错误');
	})
	socket.on('disconnect', err => {
		// console.log('退出登录');
		loginStatus = 'no'
	})
	
	return im
}

/**登录
 * @param {Object} param
 */
function login(param) {
	return new Promise((resolve, reject) => {
		if (!param.user_id) {
			reject('请输入登录用户');
		}
		if (loginStatus != 'no') {
			reject('登录中');
		}
		
		if (socket) {
			loginStatus = 'loging'
			socket.io.opts.query = param
			socket.connect()
			socket.emit('login', param, (res) => {
				
				if (res.code == 0 && res.data.user_id) {
					resolve(res.data);
                    localStorage.setItem(`im_user_profile`, JSON.stringify(res.data))
					imUser = res.data
					loginStatus = 'logined'
				} else {
					// console.error('user login fail---');
					reject(res.data);
					loginStatus = 'no'
				}
			})
		}else{
			loginStatus = 'no'
		}
	})
}

/**退出登录
 * @param {Object} param
 */
function logout() {
	socket.off('connect')
	socket.off('disconnect')
	socket.off('connect_timeout')
	socket.off('error')
	loginStatus = 'no'
	if (socket && imUser && imUser.user_id) {
		imUser = null
		// console.log('退出登录');
		
		socket.disconnect()
		createSocket()
	}
}


function on(event, fn) {
	socket.on(event, fn)
}

function off(event, fn) {
	socket.off(event, fn)
}

/**
 * 断开socket
 */
function socketStop() {
	if (socket) {
		socket.close()
		socket = null
		imUser = null
	}
}

/**
 * 获取会话列表
 * @param {Object} param
 */
function getConversationList(param) {
    if(!imUser || !imUser.user_id){
        return Promise.reject()
    }
	let data = {
		from: imUser.user_id,
		pageSize:20,
		...param
	}
	return api.get('chat/conversation/getList', data)
}


/**
 * 获取会话信息
 * @param {Object} conversationID
 */
function getConversationProfile(conversationID) {
	let data = {
		conversationID,
		from: imUser.user_id
	}
	return new Promise((resolve,reject)=>{
		api.get('chat/conversation/getConversationProfile', data).then(res=>{
			if(res.data.data.conversation){
				resolve(res)
			}else{
				reject()
			}
		}).catch(()=>{
			reject()
		})
   })
}

/**
 * 获取消息列表
 * @param {Object} param
 */
function getMessageList(param) {
	if(!imUser || !imUser.user_id){
        return Promise.reject()
    }
	let data = {
		...param,
		from: imUser.user_id
	}
	return api.get('chat/message/getMessageList', data)
}

/**
 * 获取未读消息总数
 */
function getTotalUnreadMessageCount(){
	if(!imUser || !imUser.user_id){
		return Promise.reject()
	}
	let data = {
		from: imUser.user_id
	}
	return new Promise((resolve, reject) => {
		if (socket) {
			socket.emit('total_unread_count', data, (res) => {
				resolve(res);
			})
		} else {
			reject()
		}
	})
}

function getMyProfile(param) {
	return Promise.resolve({
		data: imUser
	})
}


function updateMyProfile(param) {
	if(!imUser || !imUser.user_id){
        return Promise.reject()
    }
	let data = {
		...param,
		user_id: imUser.user_id
	}
	return api.post('chat/imUser/update', data)
}


/**
 * 会话消息已读
 * @param {Object} param
 */
function setMessageRead(param) {
	if(!imUser || !imUser.user_id){
		return Promise.reject()
	}
	let data = {
		...param,
		from: imUser.user_id
	}
	return new Promise((resolve, reject) => {
		if (socket) {
			socket.emit('conversation_read', data, (res) => {
				resolve(res);
			})
		} else {
			reject()
		}
	})
}

/**
 * 发送消息
 */
function sendMessage(msg) {
	return new Promise((resolve, reject) => {
		if (msg.type == 'TIMImageElem') {
			uploadImageMessage(msg).then(val => {
				if (socket) {
					socket.emit('send_message', val, (res) => {
						if (res.id) {
							resolve(res);
						} else {
							reject()
						}

					})
				} else {
					reject()
				}
			})
		} else {
			if (socket) {
				
				socket.emit('send_message', msg, (res) => {
					if (res.id) {
					
						resolve(res);
					} else {
						reject()
					}
				})
			} else {
				reject()
			}
		}
	})


}

function createTextMessage(v) {
	let msg = createMessageTemp(v)
	msg.type = 'TIMTextElem'
	return msg
}


function createLocationMessage(v) {
	let msg = createMessageTemp(v)
	msg.type = "TIMLocationElem"
	return msg
}

function createImageMessage(v) {
	let msg = createMessageTemp(v)
	msg.type = 'TIMImageElem'
	return msg
}

function createCustomMessage(v) {
	let msg = createMessageTemp(v)
	msg.type = "TIMCustomElem"
	return msg
}

function createVideoMessage(v) {
	let msg = createMessageTemp(v)
	msg.type = 'TIMVideoFileElem'
	return msg
}



function createMessageTemp(v) {
	let time = new Date().getTime() / 1000
	let msg = {
		...v,
		id: guid(),
		nick: imUser.nick,
		avatar: imUser.avatar,
		clientSequence: time,
		clientTime: time,
		time: time,
		flow: 'out',
		from: imUser.user_id
	}

	return msg
}


function revokeMessage(param) {
	return new Promise((resolve, reject) => {
		if (socket) {
			socket.emit('message_revoked', param, (res) => {
				if (res.code == 0) {
					resolve(res);
				} else {
					reject()
				}
			})
		} else {
			reject()
		}
	})
}

function deleteMessage(param) {

	return new Promise((resolve, reject) => {
		if (socket) {
			if (!param || param.length == 0) {
				reject()
			}

			socket.emit('message_delete', param, (res) => {
				if (res.code == 0) {
					resolve(res);
				} else {
					reject()
				}
			})
		} else {
			reject()
		}
	})
}


let im = {
	login,
	on,
	off,
	getConversationList,
	getConversationProfile,
	getMyProfile,
	setMessageRead,
	createTextMessage,
	createLocationMessage,
	createImageMessage,
	createCustomMessage,
	createVideoMessage,
	sendMessage,
	getMessageList,
	deleteMessage,
	revokeMessage,
	logout,
	getTotalUnreadMessageCount,
	updateMyProfile

}

export default {
	createSocket
};


function getKeyName() {
	return getPicKeyName()
}

function getToken() {
	const key = getKeyName()
	return new Promise((resolve, reject) => {
		api.post("https://www.curiousmore.com/qmore/push/upload/getToken", {
			key
		}).then(res => {
			let tokenInfo = {
				key,
				token: res.data.data
			}
			resolve(tokenInfo)
		}).catch(err => {
			reject(err)
			Message('上传失败')
		})
	})

}

function uploadImageMessage(msg) {
	
	return new Promise((resolve, reject) => {
		getToken().then(tokenInfo => {
			let file = msg.payload.file

			var formData = new FormData();
			formData.append("file", file);
			formData.append("token", tokenInfo.token);
			formData.append("key", tokenInfo.key);
			   api.post('http://up-z0.qiniup.com',formData)
			   .then((res) => {
				let payload = {
					imageFormat: 1,
					imageInfoArray: [{
						imageUrl: 'https://img.curiousmore.com/' + tokenInfo.key,
						url: 'https://img.curiousmore.com/' + tokenInfo.key,
						size: msg.payload.file.size
					}]
				}
				msg.payload = payload
				resolve(msg)
			   }).catch(err=>{
				reject()
                Message('上传失败')
			   })
		})
	})

}